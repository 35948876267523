export const bloodGroupTypeConstants = [
  { name: "A(+)", value: "A+" },
  { name: "A(-)", value: "A-" },
  { name: "B(+)", value: "B+" },
  { name: "B(-)", value: "B-" },
  { name: "O(+)", value: "O+" },
  { name: "O(-)", value: "O-" },
  { name: "AB(+)", value: "AB+" },
  { name: "AB(-)", value: "AB-" },
];
