var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card-actions', {
    attrs: {
      "title": "Filters",
      "action-collapse": ""
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mb-1 custom-font",
    attrs: {
      "options": _vm.filterEmpIdOption,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "name",
      "placeholder": "Select Employee"
    },
    on: {
      "input": function input($event) {
        return _vm.loadItems('employee');
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('UserSelect', {
          attrs: {
            "user": data
          }
        })];
      }
    }]),
    model: {
      value: _vm.selectEmpId,
      callback: function callback($$v) {
        _vm.selectEmpId = $$v;
      },
      expression: "selectEmpId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "lg": "3",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mb-1 custom-font",
    attrs: {
      "options": _vm.filterDivisionIdOption,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "name",
      "placeholder": "Select Division"
    },
    on: {
      "input": function input($event) {
        return _vm.loadEmp('division');
      }
    },
    model: {
      value: _vm.filterDivisionId,
      callback: function callback($$v) {
        _vm.filterDivisionId = $$v;
      },
      expression: "filterDivisionId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "2",
      "lg": "2",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mb-1 custom-font",
    attrs: {
      "options": _vm.filterDepartmentIdOption,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "name",
      "placeholder": "Select Department"
    },
    on: {
      "input": function input($event) {
        return _vm.loadEmp('department');
      }
    },
    model: {
      value: _vm.filterDepartmentId,
      callback: function callback($$v) {
        _vm.filterDepartmentId = $$v;
      },
      expression: "filterDepartmentId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "2",
      "lg": "2",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mb-1 custom-font",
    attrs: {
      "options": _vm.filterDesignationIdOption,
      "reduce": function reduce(item) {
        return item.id;
      },
      "label": "name",
      "placeholder": "Select Designation"
    },
    on: {
      "input": function input($event) {
        return _vm.loadEmp('designation');
      }
    },
    model: {
      value: _vm.filterDesignationId,
      callback: function callback($$v) {
        _vm.filterDesignationId = $$v;
      },
      expression: "filterDesignationId"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "2",
      "lg": "2",
      "xs": "12"
    }
  }, [_c('v-select', {
    staticClass: "mb-1 custom-font",
    attrs: {
      "options": _vm.statusIdOption,
      "reduce": function reduce(item) {
        return item.value;
      },
      "label": "name",
      "placeholder": "Select Status"
    },
    on: {
      "input": _vm.filterStatusWiseInfo
    },
    model: {
      value: _vm.filterStatusId,
      callback: function callback($$v) {
        _vm.filterStatusId = $$v;
      },
      expression: "filterStatusId"
    }
  })], 1)], 1)], 1), _c('b-card', [_c('div', [_c('div', {
    staticClass: "custom-search d-flex align-items-center justify-content-end"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
  }, [_vm.$permissionAbility(_vm.USERS_CREATE, _vm.permissions) ? [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "flex-shrink-0",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.showModal
    }
  }, [_vm._v(" Add Employee ")])] : _vm._e()], 2)]), _c('vue-good-table', {
    attrs: {
      "mode": "remote",
      "total-rows": _vm.totalRecords,
      "is-loading": _vm.isLoading,
      "rows": _vm.rows,
      "sort-options": {
        enabled: false,
        multipleColumns: true,
        initialSortBy: [{
          field: 'id',
          type: 'desc'
        }, {
          field: 'name',
          type: 'desc'
        }, {
          field: 'created_at',
          type: 'desc'
        }]
      },
      "columns": _vm.columns,
      "pagination-options": {
        enabled: true,
        perPage: _vm.pageLength
      },
      "style-class": "vgt-table striped"
    },
    on: {
      "update:isLoading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "update:is-loading": function updateIsLoading($event) {
        _vm.isLoading = $event;
      },
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-column-filter": _vm.onColumnFilter,
      "on-per-page-change": _vm.onPerPageChange
    },
    scopedSlots: _vm._u([{
      key: "table-row",
      fn: function fn(props) {
        return [props.column.field === 'format_name' ? _c('span', [_c('UserCard', {
          attrs: {
            "user": props.row
          }
        })], 1) : _vm._e(), props.column.field === 'status' ? _c('span', [_c('b-badge', {
          attrs: {
            "variant": _vm.statusVariant(props.row.status)
          }
        }, [_vm._v(" " + _vm._s(props.row.status ? "Active" : "Inactive") + " ")])], 1) : props.column.field === 'action' ? _c('span', [_vm.$permissionAbility(_vm.USERS_PROFILE_ACCESS, _vm.permissions) ? [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.onShowPage(props.row.id);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "EyeIcon",
            "title": "Profile",
            "size": "16"
          }
        })], 1)] : _vm._e(), _vm.$permissionAbility(_vm.USERS_EDIT, _vm.permissions) ? [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.onShow(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "Edit2Icon",
            "title": "Edit",
            "size": "16"
          }
        })], 1)] : _vm._e(), props.row.super_admin !== 1 && props.row.admin !== 1 && _vm.$permissionAbility(_vm.USERS_DELETE, _vm.permissions) ? [_c('span', {
          on: {
            "click": function click($event) {
              return _vm.onPermanentlyDelete(props.row);
            }
          }
        }, [_c('feather-icon', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover",
            modifiers: {
              "hover": true
            }
          }],
          staticClass: "mr-50 custom-icon cursor-pointer",
          attrs: {
            "icon": "TrashIcon",
            "title": "Delete",
            "size": "16"
          }
        })], 1)] : _vm._e()], 2) : _c('span', [_vm._v(" " + _vm._s(props.formattedRow[props.column.field]) + " ")])];
      }
    }, {
      key: "pagination-bottom",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "d-flex justify-content-between flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex align-items-center mb-0 mt-1"
        }, [_c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" Showing 1 to ")]), _c('b-form-select', {
          staticClass: "mx-1",
          attrs: {
            "options": ['10', '25', '50', '100', '500']
          },
          on: {
            "input": function input(value) {
              return props.perPageChanged({
                currentPerPage: value
              });
            }
          },
          model: {
            value: _vm.pageLength,
            callback: function callback($$v) {
              _vm.pageLength = $$v;
            },
            expression: "pageLength"
          }
        }), _c('span', {
          staticClass: "text-nowrap"
        }, [_vm._v(" of " + _vm._s(props.total) + " entries ")])], 1), _c('div', [_c('b-pagination', {
          staticClass: "mt-1 mb-0",
          attrs: {
            "value": 1,
            "total-rows": props.total,
            "per-page": _vm.pageLength,
            "first-number": "",
            "last-number": "",
            "align": "right",
            "prev-class": "prev-item",
            "next-class": "next-item"
          },
          on: {
            "input": function input(value) {
              return props.pageChanged({
                currentPage: value
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "prev-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronLeftIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }, {
            key: "next-text",
            fn: function fn() {
              return [_c('feather-icon', {
                attrs: {
                  "icon": "ChevronRightIcon",
                  "size": "18"
                }
              })];
            },
            proxy: true
          }], null, true)
        })], 1)])];
      }
    }])
  })], 1), _c('b-modal', {
    attrs: {
      "id": "modal-users-form",
      "centered": "",
      "title": _vm.modelType === 'editModel' ? 'Edit Employee' : 'Register Employee',
      "hide-footer": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "hidden": _vm.hiddenModal
    }
  }, [_c('validation-observer', {
    ref: "usersValidation"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.validationForm($event);
      }
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Profile Image ",
      "label-for": "avatar"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "profile image",
      "vid": "avatar",
      "rules": "size:2048|ext:jpg,jpeg,png"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "xs": "6",
      "lg": "6",
      "md": "6"
    }
  }, [_c('b-avatar', {
    ref: "previewEl",
    attrs: {
      "src": _vm.previewAvatar,
      "size": "90px",
      "rounded": ""
    }
  })], 1), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "xs": "6",
      "lg": "6",
      "md": "6"
    }
  }, [_c('b-button', {
    staticClass: "btn-sm mr-1",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        return _vm.$refs.refInputEl.click();
      }
    }
  }, [_c('input', {
    ref: "refInputEl",
    staticClass: "d-none",
    attrs: {
      "type": "file",
      "accept": "image/*"
    },
    on: {
      "input": _vm.uploadImage
    }
  }), _c('feather-icon', {
    attrs: {
      "icon": "UploadIcon"
    }
  })], 1), _c('b-button', {
    staticClass: "btn-sm mr-1 mt-1",
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": _vm.removeImage
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MinusSquareIcon"
    }
  })], 1)], 1)], 1), _vm.imageSizeError ? [_c('small', {
    staticClass: "text-danger"
  }, [_vm._v(_vm._s(_vm.imageSizeError))])] : _vm._e()], 2)], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Name",
      "label-for": "name"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "name",
      "vid": "name",
      "rules": "".concat(_vm.modelType == 'editModel' ? '' : 'required', "|max:255")
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "name",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "name": "name",
            "placeholder": "Enter Full Name"
          },
          model: {
            value: _vm.name,
            callback: function callback($$v) {
              _vm.name = $$v;
            },
            expression: "name"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Employee ID ",
      "label-for": "employee-number"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "employee id",
      "vid": "employee_number",
      "rules": "required|max:255"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "employee-number",
            "type": "text",
            "state": errors.length > 0 ? false : null,
            "placeholder": "EM-220001"
          },
          model: {
            value: _vm.employeeNumber,
            callback: function callback($$v) {
              _vm.employeeNumber = $$v;
            },
            expression: "employeeNumber"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Machine Emp ID",
      "label-for": "machine-employee-number"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "machine employee id",
      "vid": "machine_user_id",
      "rules": "numeric"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "machine-employee-number",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "placeholder": "220001"
          },
          model: {
            value: _vm.machineEmployeeId,
            callback: function callback($$v) {
              _vm.machineEmployeeId = $$v;
            },
            expression: "machineEmployeeId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "National ID / Social Security Number ",
      "label-for": "nid"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "national id / social security number",
      "rules": "".concat(_vm.modelType === 'editModel' ? '' : 'required'),
      "vid": "nid"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "nid",
            "state": errors.length > 0 ? false : null,
            "name": "nid",
            "placeholder": "NID / SSN Number"
          },
          model: {
            value: _vm.nid,
            callback: function callback($$v) {
              _vm.nid = $$v;
            },
            expression: "nid"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Email ",
      "label-for": "email"
    }
  }, [_c('validation-provider', {
    attrs: {
      "type": "email",
      "name": "email",
      "rules": "".concat(_vm.modelType === 'editModel' ? '' : 'required', "|email"),
      "vid": "email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "email",
            "state": errors.length > 0 ? false : null,
            "name": "email",
            "placeholder": "john@example.com"
          },
          model: {
            value: _vm.email,
            callback: function callback($$v) {
              _vm.email = $$v;
            },
            expression: "email"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Personal Email ",
      "label-for": "email"
    }
  }, [_c('validation-provider', {
    attrs: {
      "type": "email",
      "name": "personal email",
      "rules": "required|email",
      "vid": "personal_email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "personal_email",
            "state": errors.length > 0 ? false : null,
            "name": "personal email",
            "placeholder": "john@example.com"
          },
          model: {
            value: _vm.personal_email,
            callback: function callback($$v) {
              _vm.personal_email = $$v;
            },
            expression: "personal_email"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Mobile ",
      "label-for": "mobile"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "mobile",
      "rules": "".concat(_vm.modelType === 'editModel' ? '' : 'required'),
      "vid": "mobile"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "mobile",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "name": "mobile",
            "placeholder": "Mobile Number"
          },
          model: {
            value: _vm.mobile,
            callback: function callback($$v) {
              _vm.mobile = $$v;
            },
            expression: "mobile"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Date Of Birth ",
      "label-for": "dob"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "date of birth",
      "vid": "dob",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('b-form-datepicker', {
          staticClass: "custom-font",
          attrs: {
            "id": "dob",
            "state": errors.length > 0 ? false : null,
            "locale": "en-US",
            "today-button": "",
            "close-button": "",
            "reset-button": ""
          },
          on: {
            "context": _vm.onContextPurchaseDate
          },
          model: {
            value: _vm.dob,
            callback: function callback($$v) {
              _vm.dob = $$v;
            },
            expression: "dob"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Blood Group ",
      "label-for": "blood-group"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "blood group",
      "vid": "blood_group",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "status",
            "options": _vm.bloodGroupTypeConstants,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "name",
            "placeholder": "Choose Here"
          },
          model: {
            value: _vm.selectBloodGroupValue,
            callback: function callback($$v) {
              _vm.selectBloodGroupValue = $$v;
            },
            expression: "selectBloodGroupValue"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Religion ",
      "label-for": "religion"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "religion",
      "vid": "religion",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var errors = _ref10.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "religion",
            "options": _vm.religionValueOption,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "name",
            "placeholder": "Choose Here"
          },
          model: {
            value: _vm.selectReligionValue,
            callback: function callback($$v) {
              _vm.selectReligionValue = $$v;
            },
            expression: "selectReligionValue"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Gender ",
      "label-for": "gender"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "gender",
      "vid": "gender",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var errors = _ref11.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "gender",
            "placeholder": "Choose a gender",
            "options": _vm.genderOption,
            "reduce": function reduce(option) {
              return option.value;
            }
          },
          on: {
            "input": _vm.updateLeaveTypeOptions
          },
          model: {
            value: _vm.selectGenderId,
            callback: function callback($$v) {
              _vm.selectGenderId = $$v;
            },
            expression: "selectGenderId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Marital Status ",
      "label-for": "martial status"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "marital status",
      "vid": "marital_status",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var errors = _ref12.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "marital_status",
            "options": _vm.maritalStatusValueOption,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "name",
            "placeholder": "Choose Here"
          },
          model: {
            value: _vm.selectMaritalStatusValue,
            callback: function callback($$v) {
              _vm.selectMaritalStatusValue = $$v;
            },
            expression: "selectMaritalStatusValue"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Work Type",
      "label-for": "work_type"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "Work Type",
      "vid": "work_type",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var errors = _ref13.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "work_type",
            "options": _vm.workTypeValueOption,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "name",
            "placeholder": "Choose Here"
          },
          model: {
            value: _vm.selectWorkTypeValue,
            callback: function callback($$v) {
              _vm.selectWorkTypeValue = $$v;
            },
            expression: "selectWorkTypeValue"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Join Date ",
      "label-for": "join-date"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "join date",
      "vid": "join_date",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var errors = _ref14.errors;
        return [_c('b-form-datepicker', {
          staticClass: "custom-font",
          attrs: {
            "id": "join-date",
            "state": errors.length > 0 ? false : null,
            "locale": "en-US",
            "today-button": "",
            "close-button": "",
            "reset-button": ""
          },
          on: {
            "context": _vm.onContextPurchaseDate
          },
          model: {
            value: _vm.joinDate,
            callback: function callback($$v) {
              _vm.joinDate = $$v;
            },
            expression: "joinDate"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Designation ",
      "label-for": "designation-id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "designation",
      "vid": "designation_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var errors = _ref15.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "designation-id",
            "placeholder": "Choose a designation",
            "options": _vm.designationIdOption,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name"
          },
          model: {
            value: _vm.selectDesignationId,
            callback: function callback($$v) {
              _vm.selectDesignationId = $$v;
            },
            expression: "selectDesignationId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Role ",
      "label-for": "role-id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "role",
      "vid": "role_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref16) {
        var errors = _ref16.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "role-id",
            "placeholder": "Choose a role",
            "options": _vm.roleIdOption,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name"
          },
          model: {
            value: _vm.selectRoleId,
            callback: function callback($$v) {
              _vm.selectRoleId = $$v;
            },
            expression: "selectRoleId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Agreement Renewal Date",
      "label-for": "agreement-renewal-date"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "agreement renewal date",
      "vid": "agreement_renewal_date",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref17) {
        var errors = _ref17.errors;
        return [_c('b-form-datepicker', {
          staticClass: "custom-font",
          attrs: {
            "id": "agreement renewal date",
            "state": errors.length > 0 ? false : null,
            "locale": "en-US",
            "today-button": "",
            "close-button": "",
            "reset-button": ""
          },
          on: {
            "context": _vm.onContextPurchaseDate
          },
          model: {
            value: _vm.agreementRenewalDate,
            callback: function callback($$v) {
              _vm.agreementRenewalDate = $$v;
            },
            expression: "agreementRenewalDate"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Festival Bonus Eligibility Months",
      "label-for": "festival_bonus_eligibility_months"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Festival Bonus Eligibility Months",
      "vid": "festival_bonus_eligibility_months",
      "rules": "integer|min:1|max:24"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref18) {
        var errors = _ref18.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "festival_bonus_eligibility_months",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "name": "festival_bonus_eligibility_months",
            "placeholder": "Enter Months"
          },
          model: {
            value: _vm.festivalBonusEligibilityMonths,
            callback: function callback($$v) {
              _vm.festivalBonusEligibilityMonths = $$v;
            },
            expression: "festivalBonusEligibilityMonths"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Department ",
      "label-for": "department-id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "department",
      "vid": "department_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref19) {
        var errors = _ref19.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "department-id",
            "placeholder": "Choose a department",
            "options": _vm.departmentIdOption,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name"
          },
          on: {
            "input": function input($event) {
              return _vm.departmentChanged();
            }
          },
          model: {
            value: _vm.selectDepartmentId,
            callback: function callback($$v) {
              _vm.selectDepartmentId = $$v;
            },
            expression: "selectDepartmentId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _vm.modelType === '' ? _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Attendance Policy ",
      "label-for": "attendance_policy"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "attendance policy",
      "vid": "attendance_policy",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref20) {
        var errors = _ref20.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "attendance_policy",
            "placeholder": "Choose a attendance policy",
            "options": _vm.attendancePolicyOption,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name"
          },
          model: {
            value: _vm.selectAttendancePolicyId,
            callback: function callback($$v) {
              _vm.selectAttendancePolicyId = $$v;
            },
            expression: "selectAttendancePolicyId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3621757238)
  })], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Employee Type ",
      "label-for": "employee-type-id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "employee type",
      "vid": "employee_type_id",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref21) {
        var errors = _ref21.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "employee-type-id",
            "placeholder": "Choose a employee type",
            "options": _vm.employeeTypesOption,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name"
          },
          on: {
            "input": _vm.updateLeaveTypeOptions
          },
          model: {
            value: _vm.selectEmployeeTypesId,
            callback: function callback($$v) {
              _vm.selectEmployeeTypesId = $$v;
            },
            expression: "selectEmployeeTypesId"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Select Leave Type",
      "label-for": "leave-type-id"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "leave type",
      "vid": "leave_type_id",
      "autocomplete": "off"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref22) {
        var errors = _ref22.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "leave-type-id",
            "placeholder": "Choose leave ",
            "options": _vm.leaveTypesOption,
            "reduce": function reduce(option) {
              return option.id;
            },
            "label": "name",
            "autocomplete": "off",
            "multiple": ""
          },
          model: {
            value: _vm.selectLeaveTypesIds,
            callback: function callback($$v) {
              _vm.selectLeaveTypesIds = $$v;
            },
            expression: "selectLeaveTypesIds"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Status ",
      "label-for": "status"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "status",
      "vid": "status",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref23) {
        var errors = _ref23.errors;
        return [_c('v-select', {
          staticClass: "custom-font",
          attrs: {
            "id": "status",
            "options": _vm.statusValueOption,
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "name"
          },
          model: {
            value: _vm.selectStatusValue,
            callback: function callback($$v) {
              _vm.selectStatusValue = $$v;
            },
            expression: "selectStatusValue"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _vm.modelType == '' ? [_c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Monthly Salary ",
      "label-for": "salary"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "salary",
      "rules": "".concat(_vm.modelType === 'editModel' ? '' : 'required'),
      "vid": "salary"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref24) {
        var errors = _ref24.errors;
        return [_c('b-form-input', {
          staticClass: "custom-font",
          attrs: {
            "id": "salary",
            "type": "number",
            "state": errors.length > 0 ? false : null,
            "name": "salary",
            "placeholder": "Monthly Salary Amount"
          },
          model: {
            value: _vm.salary,
            callback: function callback($$v) {
              _vm.salary = $$v;
            },
            expression: "salary"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3931976452)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Password ",
      "label-for": "password"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "password",
      "rules": "".concat(_vm.modelType == 'editModel' ? '' : 'required', "|min:6"),
      "vid": "password"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref25) {
        var errors = _ref25.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge",
          class: errors.length > 0 ? 'is-invalid' : null
        }, [_c('b-form-input', {
          staticClass: "form-control-merge custom-font",
          attrs: {
            "id": "password",
            "state": errors.length > 0 ? false : null,
            "type": _vm.passwordFieldType,
            "name": "user-password",
            "placeholder": "············"
          },
          model: {
            value: _vm.password,
            callback: function callback($$v) {
              _vm.password = $$v;
            },
            expression: "password"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "id": "passwordToggleBtn",
            "is-text": ""
          },
          on: {
            "click": _vm.togglePasswordVisibility
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": _vm.passwordToggleIcon
          }
        })], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3600069127)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "lg": "4",
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "required-label",
    attrs: {
      "label": "Confirm Password ",
      "label-for": "password_confirmation"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "password confirmation",
      "rules": "".concat(_vm.modelType == 'editModel' ? '' : 'required', "|confirmed:password"),
      "vid": "password_confirmation"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref26) {
        var errors = _ref26.errors;
        return [_c('b-input-group', {
          staticClass: "input-group-merge",
          class: errors.length > 0 ? 'is-invalid' : null
        }, [_c('b-form-input', {
          staticClass: "form-control-merge",
          attrs: {
            "id": "password_confirmation",
            "state": errors.length > 0 ? false : null,
            "name": "password_confirmation",
            "placeholder": "············",
            "type": _vm.confirmPasswordFieldType
          },
          model: {
            value: _vm.password_confirmation,
            callback: function callback($$v) {
              _vm.password_confirmation = $$v;
            },
            expression: "password_confirmation"
          }
        }), _c('b-input-group-append', {
          attrs: {
            "id": "passwordToggleBtn",
            "is-text": ""
          },
          on: {
            "click": _vm.toggleConfirmPasswordVisibility
          }
        }, [_c('feather-icon', {
          staticClass: "cursor-pointer",
          attrs: {
            "icon": _vm.confirmPasswordToggleIcon
          }
        })], 1)], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2876506964)
  })], 1)], 1)] : _vm._e(), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.isUserFormSubmitLoading ? [_c('b-button', {
    staticClass: "float-right",
    attrs: {
      "variant": "primary",
      "disabled": ""
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  }), _vm._v(" Loading... ")], 1)] : [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "float-right",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.modelType === "editModel" ? "Update" : "Submit") + " ")])]], 2)], 2)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }